.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.list--reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg--grey {
  background-color: #f3f3f3;
}

.bg--grey .inner {
  margin: 0 auto;
}

.contact__form {
  max-width: rem(856);
  padding: rem(48) rem(20) rem(56);
  margin: 0 auto;
}

@media (min-width: 767px) {
  .contact__form h2 {
    font-size: rem(40);
  }
}

.contact__form p {
  max-width: rem(560);
  color: #555555;
  line-height: 1.25;
}

.contact__form .fancyForm {
  margin-top: rem(10);
}

.contact__form .fancyForm textarea {
  height: rem(246);
}

.contact__info {
  position: relative;
  background-color: #3d6da2;
  color: #ffffff;
  overflow: hidden;
}

.contact__info:before {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 50%;
  width: auto;
  height: 100%;
  margin-left: rem(50);
  background-color: #07447a;
}

@media (min-width: 767px) {
  .contact__info:before {
    display: block;
  }
}

.contact__info:after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 120%;
  background: #07447a;
  margin-left: rem(50);
  transform-origin: 0 0;
  transform: rotate(19.5deg);
}

@media (min-width: 767px) {
  .contact__info:after {
    display: block;
  }
}

.contact__info__follow a {
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #fff;
  font-size: 3.5rem;
  opacity: 0.8;
}

.contact__info__follow a:hover {
  opacity: 1;
}

.contact__info__inner {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: rem(800);
  margin: 0 auto;
  z-index: 1;
}

.contact__info__col {
  flex: 0 1 100%;
  text-align: center;
  padding: rem(36) rem(20);
}

.contact__info__col--blue {
  background-color: #07447a;
}

@media (min-width: 767px) {
  .contact__info__col {
    flex-basis: calc(50% - 50px);
    text-align: left;
  }
  .contact__info__col--blue {
    background-color: transparent;
  }
}

.contact__info--locate a {
  color: #fff;
  text-decoration: none;
}

.contact__info--locate a:hover {
  text-decoration: underline;
}

.contact__info h3 {
  font-family: "Merriweather", Georgia, serif;
  font-size: rem(30);
  font-style: italic;
  font-weight: heavy;
  margin-top: 0;
  margin-bottom: rem(16);
}

.contact__map {
  position: relative;
  max-height: rem(496);
  overflow: hidden;
  min-height: 400px;
}

.contact__directions {
  position: absolute;
  top: 2rem;
  right: 4rem;
  z-index: 2;
}

.marker {
  display: block;
  width: 35px;
  height: 90px;
  background: url(/static/images/marker.png) no-repeat;
  background-size: contain;
}
