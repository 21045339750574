.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.list--reset, .about__testimonials__dots {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg--grey {
  background-color: #f3f3f3;
}

.bg--grey .inner {
  margin: 0 auto;
}

section {
  position: relative;
  z-index: 1;
}

.about__expertise {
  padding: 2rem 0;
  text-align: center;
}

@media (min-width: 767px) {
  .about__expertise {
    padding: 4rem 0;
  }
}

.about__orbs {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  transform-origin: left top;
}

@media (min-width: 767px) {
  .about__orbs {
    transform: scale(0.7);
    padding-left: 20px;
    height: 300px;
  }
}

@media (min-width: 1023px) {
  .about__orbs {
    transform: scale(1);
  }
}

.about__testimonials {
  text-align: center;
  width: 90%;
  padding: 3rem 0;
}

.about__testimonials__item {
  display: block;
  position: relative;
  padding: 1rem;
  font-size: 1.8rem;
  color: #333;
  letter-spacing: -1px;
  font-family: "MuseoSansW01-500Italic", sans-serif;
}

@media (min-width: 767px) {
  .about__testimonials__item {
    font-size: 2.3rem;
    padding: 2rem;
    line-height: 1.2;
  }
}

@media (min-width: 767px) {
  .about__testimonials__item > div {
    padding: 1rem 5rem;
  }
}

.about__testimonials__item > div:before, .about__testimonials__item > div:after {
  content: '\201C';
  position: absolute;
  font-family: 'Georgia', serif;
  font-size: 8rem;
  line-height: 1;
  opacity: 0.1;
}

@media (min-width: 767px) {
  .about__testimonials__item > div:before, .about__testimonials__item > div:after {
    font-size: 13rem;
  }
}

.about__testimonials__item > div:before {
  left: 0;
  top: -1rem;
}

.about__testimonials__item > div:after {
  bottom: -6rem;
  right: 1rem;
  content: '\201D';
}

.about__testimonials__name {
  font-family: "Museo Sans W01_500", sans-serif;
  font-size: 1.4rem;
}

.about__testimonials__dots {
  display: flex;
  justify-content: center;
  outline: none;
  padding: 2rem 0;
}

.about__testimonials__dots li {
  display: inline-block;
  text-indent: -9999px;
  width: 11px;
  height: 11px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 1rem;
  cursor: pointer;
}

.about__testimonials__dots li.slick-active {
  background-color: #015095;
}

.about__testimonials__list {
  transition: all 700ms 1s ease-out;
  opacity: 0;
}

.about__testimonials__list.active {
  opacity: 1;
}

.about .intro {
  font-family: "Museo Sans W01_500", sans-serif;
  font-weight: normal;
  line-height: 1.2;
  margin: 2rem auto;
  font-size: 1.4rem;
}

@media (min-width: 767px) {
  .about .intro {
    font-size: 2.8rem;
    max-width: 780px;
    font-size: 1.8rem;
    margin: 5rem auto 0;
  }
}

.circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  pointer-events: none;
  margin: 0;
  transform-origin: '50% 50%';
  opacity: 0.9;
  color: #fff;
  font-family: "Museo Sans W01_700", sans-serif;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  font-size: 1.3rem;
}

@media (min-width: 767px) {
  .circle {
    width: auto;
    width: 150px;
    height: 150px;
    font-size: 1.5rem;
  }
}

.square {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

@media (min-width: 767px) {
  .square {
    width: 180px;
    height: 160px;
    margin-left: -3.5rem;
  }
}

.orb--marketing {
  background-color: #63478a;
}

.orb--technical {
  background-color: #e5bb73;
}

.orb--engineering {
  background-color: #5acba9;
}

.orb--manufacturing {
  background-color: #f17771;
}

.orb--sales {
  background-color: #c14c66;
}
