@import '../../../../bower_components/breakpoint-sass/stylesheets/_breakpoint';
@import '../abstract/vars';
@import '../abstract/mixins';
@import '../abstract/helpers';

$filter_bp: 976px;
$toggle_bp: 676px;

.faqs {

    &__filter {

        .inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 auto;
            padding-top: rem(10);
            padding-bottom: rem(10);
        }

        &__search {
            flex: 0 1 100%;
            position: relative;
            padding: rem(10) 0;



            @media screen and (min-width: $filter_bp) {
                flex-basis: auto;
                max-width: rem(288);
            }
        }

        &__textInput {
            display: block;
            width: 100%;
            height: 100%;
            color: #949494;
            padding: rem(16) rem(64) rem(16) rem(28);
            background-color: #f7f7f7;
            border: 0;
            font-family: $f-museo-rg;
            font-size: 1.6rem;
            line-height: 1;
            outline: none;
            @include breakpoint($mobile-up) {
                font-size: 1.8rem;
            }
        }

        &__submit {
            position: absolute;
            top: 1rem;
            bottom: 1rem;
            right: 0;
            margin: 0;
            padding: 0;
            border: 0;
            width: rem(50);
            cursor: pointer;
            background: url(../static/images/icon-search.png) no-repeat center right 1rem;
            background-size: 29px 29px;


        }

    }

    &__toggle {
        flex: 0 1 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: rem(10) 0;

        &__desc {
            flex: 0 1 auto;
            padding-right: rem(12);
            color: #555555;
            font-family: $f-merriweather;
            font-size: rem(22);
            font-style: italic;
            font-weight: bold;
        }

        &__group {
            flex: 0 1 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: rem(20);

            @media screen and (min-width: $toggle_bp) {
                flex-basis: auto;
                padding-top: 0;
            }
        }

        &__option {
            width: 50%;

            &:first-child .faqs__toggle__label {
                border-right: 1px #fff solid;
            }
        }

        &__radio {
            display: none;
        }

        &__label {
            display: inline-block;
            width: 100%;
            padding: rem(20);
            background-color: lighten(desaturate(#2167a3, 50%), 10%);
            color: #e7e7e7;
            font-family: $f-museo-rg;
            font-size: rem(16);
            text-align: center;
            line-height: 1;
            transition: background-color .25s, color .25s;
            cursor: pointer;

            @media screen and (min-width: $toggle_bp) {
                font-size: rem(24);
                min-width: rem(210);
            }

            &:hover {
                background-color: #2167a3;
            }
        }

        &__radio:checked + &__label {
            background-color: #2167a3;
            color: color(white);
        }

        @media screen and (min-width: $filter_bp) {
            flex-basis: auto;
        }
    }

    &__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 auto;
        padding-top: rem(64);
        padding-bottom: rem(64);

        @include breakpoint($mobile-up) {
            min-height: 600px;
        }

        &__item {
            flex: 0 1 100%;
            margin: 0 0 rem(30);


            @include breakpoint($mobile-up) {
                flex-basis: calc(50% - 3.2rem);
                width: calc(100% - 1rem);
            }
        }

        &--results {
            padding-top: rem(20);
        }
    }

    &__search {

        padding: 2rem;
    }
}

