.clearfix:after, .resource__tile:after {
  content: "";
  display: table;
  clear: both;
}

.list--reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg--grey {
  background-color: #f3f3f3;
}

.bg--grey .inner {
  margin: 0 auto;
}

.featured {
  display: block;
  margin-top: 2rem;
}

@media (min-width: 1023px) {
  .featured {
    margin-top: -11rem;
  }
}

.featured .inner {
  padding: 0 2rem;
}

.access {
  background-color: #51769d;
  padding: 2rem 0;
}

.access .inner {
  margin: 0 auto;
}

.tiles {
  min-height: 500px;
}

.tiles .inner {
  margin: 0 auto;
}

.resource__share {
  text-align: center;
  margin-bottom: 2rem;
}

@media (min-width: 767px) {
  .resource__share {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .resource__share [data-sumome-share-pos] {
    display: flex;
    flex-direction: column;
  }
  .resource__share .sumome-share-client-wrapper {
    transform: translateX(-100%);
    animation: slideIn 300ms ease-out forwards;
  }
}

.resource__filter {
  max-width: 990px;
  margin: 0 auto;
}

.resource__filter > .flex {
  align-items: center;
  padding: 0 2rem;
}

@media (min-width: 1023px) {
  .resource__filter > .flex {
    padding: 0;
  }
}

.resource__filter__category {
  transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none;
  color: #144376;
  font-family: "Museo Sans W01_500", sans-serif;
}

@media (min-width: 1023px) {
  .resource__filter__category {
    padding: 1.5rem 1rem;
  }
}

.resource__filter__category:hover {
  background-color: #f3f3f3;
}

.resource__filter__category.active {
  font-family: "Museo Sans W01_700", sans-serif;
  color: #555;
}

.resource__filter__categories {
  display: none;
}

@media (min-width: 767px) {
  .resource__filter__categories {
    display: block;
  }
}

.resource__search {
  background-color: #f3f3f3;
  border: 0;
  padding: 1.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Museo Sans W01_500", sans-serif;
  width: 100%;
}

@media (min-width: 767px) {
  .resource__search {
    max-width: 250px;
  }
}

.resource__search::placeholder {
  color: #b3b3b3;
}

.resource__search__form {
  width: 100%;
}

@media (min-width: 767px) {
  .resource__search__form {
    width: auto;
  }
}

.resource__select {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 767px) {
  .resource__select {
    display: none;
  }
}

.resource__featured {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  max-width: 1040px;
  margin: 0 auto;
  flex-direction: column;
}

@media (min-width: 1023px) {
  .resource__featured {
    flex-direction: row;
    align-items: flex-end;
  }
}

.resource__featured__category {
  font-size: 1.2rem;
  color: #fff;
  text-transform: uppercase;
}

.resource__featured__category:before {
  display: inline-block;
  content: '';
  width: 25px;
  border-top: 1px #fff solid;
  vertical-align: middle;
  margin-right: 1rem;
}

.resource__featured__text {
  background-color: #144376;
  color: #fff;
  padding: 2rem 2.5rem 4rem;
  position: relative;
  z-index: 1;
  text-align: left;
  order: 2;
}

@media (min-width: 1023px) {
  .resource__featured__text {
    order: 1;
    width: 40%;
    padding: 2rem 4rem 4rem;
  }
}

.resource__featured__header {
  color: #fff;
  font-size: 2.4rem;
  font-style: normal;
  line-height: 1.2;
}

@media (min-width: 767px) {
  .resource__featured__header {
    font-size: 3.4rem;
  }
}

.resource__featured__image {
  order: 1;
  max-height: 460px;
  overflow-y: hidden;
}

@media (min-width: 1023px) {
  .resource__featured__image {
    max-height: none;
    margin-left: -5rem;
    width: 60%;
    order: 2;
  }
}

.resource__featured__image img {
  width: 100%;
  display: block;
}

.resource__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.resource__tile {
  width: 100%;
  margin: 1.2rem 0;
  background-color: #fff;
  position: relative;
}

@media (min-width: 767px) {
  .resource__tile {
    width: calc(50% - 1.5rem);
  }
}

@media (min-width: 1023px) {
  .resource__tile {
    width: calc(33.3% - 1.5rem);
  }
}

.resource__tile__category {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.resource__tile__header {
  font-family: "Merriweather", Georgia, serif;
  color: #015095;
  margin: 1rem 0;
  font-size: 2rem;
}

.resource__tile__header a {
  display: block;
  text-decoration: none;
  color: inherit;
}

.resource__tile__header a:hover {
  color: #144376;
}

@media (min-width: 767px) {
  .resource__tile__header {
    font-size: 2.3rem;
  }
}

.resource__tile__content {
  padding: 2rem;
  color: #818181;
  height: auto;
  margin-bottom: 3rem;
}

@media (min-width: 767px) {
  .resource__tile__content {
    font-size: 1.6rem;
  }
}

.resource__tile__image {
  width: 100%;
  display: block;
}

.resource__tile__read {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.resource__access__header {
  color: #fff;
}

@media (min-width: 767px) {
  .resource__access__header {
    font-size: 3.6rem;
  }
}

.resource__access__form {
  max-width: 600px;
  margin: 0 auto;
}

.resource__access__form p {
  margin: 2rem 0;
}

.resource__post {
  position: relative;
  z-index: 1;
}

.resource__post .flex {
  flex-direction: column;
}

@media (min-width: 1024px) {
  .resource__post .flex {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .resource__main {
    width: 60%;
    padding-right: 4rem;
  }
  .resource__main--free {
    width: 100%;
    padding-right: 0;
  }
}

.resource__main__content {
  margin: 4rem 0;
}

.resource__main__image {
  display: block;
  width: 100%;
}

.resource__form .fancyForm {
  transition: transform .5s ease-out;
}

@media (min-width: 1024px) {
  .resource__form {
    margin-top: -8rem;
    width: 40%;
  }
}

.resource__related {
  padding: 4rem 0;
}

.resource__related h2 {
  font-size: rem(24);
}

@media (min-width: 767px) {
  .resource__related h2 {
    font-size: rem(32);
    padding-left: rem(8);
    padding-right: rem(8);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
