.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.list--reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg--grey {
  background-color: #f3f3f3;
}

.bg--grey .inner {
  margin: 0 auto;
}

.faqs__filter .inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: rem(10);
  padding-bottom: rem(10);
}

.faqs__filter__search {
  flex: 0 1 100%;
  position: relative;
  padding: rem(10) 0;
}

@media screen and (min-width: 976px) {
  .faqs__filter__search {
    flex-basis: auto;
    max-width: rem(288);
  }
}

.faqs__filter__textInput {
  display: block;
  width: 100%;
  height: 100%;
  color: #949494;
  padding: rem(16) rem(64) rem(16) rem(28);
  background-color: #f7f7f7;
  border: 0;
  font-family: "Museo Sans W01_500", sans-serif;
  font-size: 1.6rem;
  line-height: 1;
  outline: none;
}

@media (min-width: 767px) {
  .faqs__filter__textInput {
    font-size: 1.8rem;
  }
}

.faqs__filter__submit {
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  right: 0;
  margin: 0;
  padding: 0;
  border: 0;
  width: rem(50);
  cursor: pointer;
  background: url(../static/images/icon-search.png) no-repeat center right 1rem;
  background-size: 29px 29px;
}

.faqs__toggle {
  flex: 0 1 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: rem(10) 0;
}

.faqs__toggle__desc {
  flex: 0 1 auto;
  padding-right: rem(12);
  color: #555555;
  font-family: "Merriweather", Georgia, serif;
  font-size: rem(22);
  font-style: italic;
  font-weight: bold;
}

.faqs__toggle__group {
  flex: 0 1 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: rem(20);
}

@media screen and (min-width: 676px) {
  .faqs__toggle__group {
    flex-basis: auto;
    padding-top: 0;
  }
}

.faqs__toggle__option {
  width: 50%;
}

.faqs__toggle__option:first-child .faqs__toggle__label {
  border-right: 1px #fff solid;
}

.faqs__toggle__radio {
  display: none;
}

.faqs__toggle__label {
  display: inline-block;
  width: 100%;
  padding: rem(20);
  background-color: #677d90;
  color: #e7e7e7;
  font-family: "Museo Sans W01_500", sans-serif;
  font-size: rem(16);
  text-align: center;
  line-height: 1;
  transition: background-color .25s, color .25s;
  cursor: pointer;
}

@media screen and (min-width: 676px) {
  .faqs__toggle__label {
    font-size: rem(24);
    min-width: rem(210);
  }
}

.faqs__toggle__label:hover {
  background-color: #2167a3;
}

.faqs__toggle__radio:checked + .faqs__toggle__label {
  background-color: #2167a3;
  color: #ffffff;
}

@media screen and (min-width: 976px) {
  .faqs__toggle {
    flex-basis: auto;
  }
}

.faqs__cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: rem(64);
  padding-bottom: rem(64);
}

@media (min-width: 767px) {
  .faqs__cards {
    min-height: 600px;
  }
}

.faqs__cards__item {
  flex: 0 1 100%;
  margin: 0 0 rem(30);
}

@media (min-width: 767px) {
  .faqs__cards__item {
    flex-basis: calc(50% - 3.2rem);
    width: calc(100% - 1rem);
  }
}

.faqs__cards--results {
  padding-top: rem(20);
}

.faqs__search {
  padding: 2rem;
}
