@import '../../../../bower_components/breakpoint-sass/stylesheets/_breakpoint';

@import '../abstract/vars';
@import '../abstract/mixins';
@import '../abstract/helpers';

section {
    position: relative;
    z-index: 1;
}

.about {

    &__expertise {
        padding: 2rem 0;
        text-align: center;

        @include breakpoint($mobile-up) {
            padding: 4rem 0;
        }
    }

    &__orbs {
        //border: 1px red solid;

        margin: 2rem 0;
        display: flex;
        justify-content: center;
        max-width: 900px;
        margin: 0 auto;
        //padding-left: 20px;
        //height: 150px;
        //border: 1px red solid;
        text-align:center;
        align-items: center;
        flex-wrap: wrap;
        transform-origin: left top;

        @include breakpoint($mobile-up) {
            transform: scale(0.7);
            padding-left: 20px;
            height: 300px;
        }

        @include breakpoint($tablet-up) {
            transform: scale(1);
        }
    }

    &__testimonials {
        text-align: center;
        width: 90%;
        padding: 3rem 0;

        &__item {
            display: block;
            //border: 1px red solid;
            position: relative;
            padding: 1rem;
            font-size: 1.8rem;
            color: #333;

            letter-spacing: -1px;
            font-family: $f-museo-rg-it;

            @include breakpoint($mobile-up) {
                font-size: 2.3rem;
                padding: 2rem;
                line-height: 1.2;
            }

            > div {

                @include breakpoint($mobile-up) {
                    padding: 1rem 5rem;
                }
            }
            > div:before, > div:after {
                content: '\201C';
                position: absolute;
                font-family: 'Georgia', serif;
                font-size: 8rem;
                line-height: 1;
                //transform: translateX(-100%) translateY(-20%);
                opacity: 0.1;

                @include breakpoint($mobile-up) {
                    font-size: 13rem;
                }
            }

            > div:before {
                left: 0;
                top: -1rem;
                //transform: translateX(-100%) translateY(-20%);
            }

            > div:after {
                //right: -8rem;
                bottom: -6rem;
                right: 1rem;
                content: '\201D';
                //transform: translateX(-100%) translateY(20%);
            }
        }

        &__name {
            font-family: $f-museo-rg;
            font-size: 1.4rem;

        }

        &__dots {
            @extend .list--reset;
            display: flex;
            justify-content: center;
            outline: none;
            padding: 2rem 0;

            li {
                display: inline-block;
                text-indent: -9999px;
                width: 11px;
                height: 11px;
                background-color: #ccc;
                border-radius: 50%;
                margin: 0 1rem;
                cursor: pointer;

                &.slick-active {
                    background-color: color(congress);
                }
            }
        }

        &__list {
            transition: all 700ms 1s ease-out;
            opacity: 0;

            &.active {
                opacity: 1;
            }
        }
    }

    .intro {
        font-family: $f-museo-rg;
        font-weight: normal;
        line-height: 1.2;
        margin: 2rem auto;
        font-size: 1.4rem;

        @include breakpoint($mobile-up) {
            font-size: 2.8rem;
            max-width: 780px;
            font-size: 1.8rem;
            margin: 5rem auto 0;

        }
    }

}

.circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  pointer-events:none;
  margin: 0;
  transform-origin: '50% 50%';
  opacity: 0.9;
  color: #fff;
  font-family: $f-museo-bd;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  font-size: 1.3rem;


  @include breakpoint($mobile-up) {
    width: auto;
    width: 150px;
    height: 150px;
    font-size: 1.5rem;
  }



}

.square {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  @include breakpoint($mobile-up) {
    width: 180px;
    height: 160px;
    margin-left: -3.5rem;
  }


}

.orb--marketing {
  background-color: #63478a;
}

.orb--technical {
  background-color: #e5bb73;
}

.orb--engineering {
  background-color: #5acba9;
}

.orb--manufacturing {
  background-color: #f17771;
}

.orb--sales {
  background-color: #c14c66;
}






