
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


@function color($color, $map: $colors) {
  @return map-get($map, $color);
}

