$mobile-up: 767px;
$tablet-up: 1023px;

$rsaquo: "\203A";

$f-museo-bd-it:     'MuseoSansW01-700Italic', sans-serif;
$f-museo-bd:        'Museo Sans W01_700', sans-serif;
$f-museo-rg-it:     'MuseoSansW01-500Italic', sans-serif;
$f-museo-rg:        'Museo Sans W01_500', sans-serif;
$f-museo-lt:        'Museo Sans W01_300', sans-serif;
$f-merriweather:    'Merriweather', Georgia, serif;

$colors: (
  white: #ffffff,
  chathams-blue: #144376,
  congress: #015095,
  concrete: #f3f3f3,
  marketing: #63478a,
  it: #e5bb73,
  management: #5acba9,
  sales: #c14c66,
  finance: #e58334,
  adminHr: #0e67a5,
  medical: #247c2c
);


// easings
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
