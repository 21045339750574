@import '../../../../bower_components/breakpoint-sass/stylesheets/_breakpoint';
@import '../abstract/vars';
@import '../abstract/mixins';
@import '../abstract/helpers';

.servicesSearch {
    &__application {
        background-color: #50769d;
        box-shadow: inset 0px rem(14) rem(20) rem(-10) rgba(#000, .2);

        .inner {
            // padding-top: rem(56);
            // padding-bottom: rem(56);
            max-width: 860px;

            @include breakpoint($mobile-up) {
                padding-top: rem(56);
                padding-bottom: rem(56);

            }
        }

        h2 { color: color(white); }

        form { margin-top: rem(20); }

        textarea { height: rem(246); }
    }

    &__companies {
        text-align: center;

        .brandList {
            @extend .list--reset;
            list-style: none;
        }
    }

    &__content {
        max-width: 860px;
        margin: 0 auto;
    }
}
