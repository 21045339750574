@import '../../../../bower_components/breakpoint-sass/stylesheets/_breakpoint';
@import '../abstract/vars';
@import '../abstract/mixins';
@import '../abstract/helpers';

.contact {

    &__form {
        max-width: rem(856);
        padding: rem(48) rem(20) rem(56);
        margin: 0 auto;

        h2 {

            @include breakpoint($mobile-up) {
                font-size: rem(40);
            }
        }

        p {
            max-width: rem(560);
            color: #555555;
            line-height: (20/16);
        }

        .fancyForm {
            margin-top: rem(10);
        }

        .fancyForm textarea {
            height: rem(246);
        }
    }

    &__info {
        position: relative;
        background-color: #3d6da2;
        color: color(white);
        overflow: hidden;

        &:before {
            display: none;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 50%;
            width: auto;
            height: 100%;
            margin-left: rem(50);
            background-color: #07447a;

            @include breakpoint($mobile-up) {
                display: block;
            }
        }

        &:after {
            display: none;
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 50%;
            height: 120%;
            background: #07447a;
            margin-left: rem(50);
            transform-origin: 0 0;
            transform: rotate(19.5deg);

            @include breakpoint($mobile-up) {
                display: block;
            }
        }

        &__follow {
            a {
                transition: all 400ms $ease-out-quart;
                color: #fff;
                font-size: 3.5rem;
                opacity: 0.8;

                &:hover {
                    opacity: 1;
                }
            }
        }

        &__inner {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: rem(800);
            margin: 0 auto;
            z-index: 1;
        }

        &__col {
            flex: 0 1 100%;
            text-align: center;
            padding: rem(36) rem(20);

            &--blue {
                background-color: #07447a;
            }

            @include breakpoint($mobile-up) {
                flex-basis: calc(50% - 50px);
                text-align: left;

                &--blue {
                    background-color: transparent;
                }
            }
        }

        &--locate {
            a {
                color: #fff;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        h3 {
            font-family: $f-merriweather;
            font-size: rem(30);
            font-style: italic;
            font-weight: heavy;
            margin-top: 0;
            margin-bottom: rem(16);
        }
    }

    &__map {
        position: relative;
        max-height: rem(496);
        overflow: hidden;
        min-height: 400px;

        // &:before {
        //     content: "";
        //     display: block;
        //     width: 1px;
        //     margin-left: -1px;
        //     padding-bottom: 75%;
        // }
    }

    &__directions {
        position: absolute;
        top: 2rem;
        right: 4rem;
        z-index: 2;
    }

}

.marker {
    display: block;
    width: 35px;
    height: 90px;
    background: url(/static/images/marker.png) no-repeat;
    background-size: contain;

}