@import '../../../../bower_components/breakpoint-sass/stylesheets/_breakpoint';

@import '../abstract/vars';
@import '../abstract/mixins';
@import '../abstract/helpers';


.featured {
    display: block;
    margin-top: 2rem;


    @include breakpoint($tablet-up) {
        margin-top: -11rem;
    }

    .inner {
        padding: 0 2rem;
    }
}

.access {
    background-color: #51769d;
    padding: 2rem 0;

    .inner {
        margin: 0 auto;
    }
}

.tiles {
    min-height: 500px;
    .inner {
        margin: 0 auto;
    }
}

.resource {

    &__intro {

    }

    &__share {
        // text-align: right;
        // margin-top: 1rem;
        text-align: center;
        margin-bottom: 2rem;


        @include breakpoint($mobile-up) {
            position: fixed;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;

            [data-sumome-share-pos] {

                display: flex;
                flex-direction: column;
            }

            .sumome-share-client-wrapper {
                transform: translateX(-100%);
                animation: slideIn 300ms ease-out forwards;
            }
        }


    }

    &__filter {
        max-width: 990px;
        margin: 0 auto;

        > .flex {
            align-items: center;
            padding: 0 2rem;

            @include breakpoint($tablet-up) {
                padding: 0;
            }
        }

        &__category {
            transition: all 500ms $ease-out-quart;
            text-decoration: none;
            color: color(chathams-blue);
            //margin-right: 1.5rem;
            font-family: $f-museo-rg;


            @include breakpoint($tablet-up) {
                padding: 1.5rem 1rem;
            }


            &:hover {
                background-color: color(concrete);

            }

            &.active {
                font-family: $f-museo-bd;
                color: #555;
            }
        }

        &__categories {
            display: none;
            @include breakpoint($mobile-up) {
                display: block;
            }
        }
    }

    &__search {
        background-color: color(concrete);
        border: 0;
        padding: 1.5rem;
        box-sizing: border-box;
        outline: none;
        font-family: $f-museo-rg;
        width: 100%;


        @include breakpoint($mobile-up) {
            max-width: 250px;
        }

        &::placeholder {
            color: lighten(#000, 70%);
        }

        &__form {
            width: 100%;

            @include breakpoint($mobile-up) {
                width: auto;
            }
        }
    }

    &__select {
        width: 100%;
        margin-bottom: 1rem;

        @include breakpoint($mobile-up) {
            display: none;
        }
    }

    &__featured {
        display: flex;
        position: relative;
        z-index: 1;
        justify-content: center;
        max-width: 1040px;
        margin: 0 auto;
        //opacity: 0;


        //align-items: flex-end;
        flex-direction: column;

        // @include breakpoint($mobile-up) {


        //     align-items: center;
        // }

        @include breakpoint($tablet-up) {
            flex-direction: row;
            align-items: flex-end;
        }


        &__category {
            font-size: 1.2rem;
            color: #fff;
            text-transform: uppercase;

            &:before {
                display: inline-block;
                content: '';
                width: 25px;
                border-top: 1px #fff solid;
                vertical-align: middle;
                margin-right: 1rem;
            }
        }


        &__text {
            background-color: color(chathams-blue);
            color: #fff;
            padding: 2rem 2.5rem 4rem;
            position: relative;
            z-index: 1;
            text-align: left;
            order: 2;



            @include breakpoint($tablet-up) {
                order: 1;
                width: 40%;
                padding: 2rem 4rem 4rem;
            }

        }

        &__header {
            color: #fff;
            font-size: 2.4rem;
            font-style: normal;
            line-height: 1.2;
            @include breakpoint($mobile-up) {
                font-size: 3.4rem;
            }
        }

        &__image {


            order: 1;
            max-height: 460px;
            overflow-y: hidden;

            @include breakpoint($tablet-up) {
                max-height: none;
                margin-left: -5rem;
                width: 60%;
                order: 2;


            }

            img {
                width: 100%;
                display: block;
            }

        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__tile {
        @extend %clearfix;

        width: 100%;
        margin: 1.2rem 0;
        background-color: #fff;
        position: relative;

        @include breakpoint($mobile-up) {
            width: calc(50% - 1.5rem);
        }

        @include breakpoint($tablet-up) {
            width: calc(33.3% - 1.5rem);
        }

        &__category {
            font-size: 1.2rem;
            text-transform: uppercase;
        }

        &__header {
            font-family: $f-merriweather;
            color: color(congress);
            margin: 1rem 0;
            font-size: 2rem;

            a {
                display: block;
                text-decoration: none;
                color: inherit;

                &:hover {
                    color: color(chathams-blue);
                }
            }

            @include breakpoint($mobile-up) {
                font-size: 2.3rem;
            }
        }

        &__content {
            padding: 2rem;
            color: #818181;
            height: auto;
            margin-bottom: 3rem;


            @include breakpoint($mobile-up) {
                font-size: 1.6rem;

            }
        }

        &__image {
            width: 100%;
            display: block;
        }

        &__read {
            //float: right;
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }

    &__access {
        &__header {
            color: #fff;

            @include breakpoint($mobile-up) {
                font-size: 3.6rem;
            }
        }

        &__form {
            max-width: 600px;
            margin: 0 auto;

            p {
                margin: 2rem 0;
            }
        }
    }

    &__post {
        position: relative;
        z-index: 1;

        .flex {
            flex-direction: column;
            @include breakpoint(1024px) {
                flex-direction: row;
            }
        }
    }

    &__main {


        @include breakpoint(1024px) {
            width: 60%;
            padding-right: 4rem;

            &--free {
                width: 100%;
                padding-right: 0;
            }
        }

        &__content {
            margin: 4rem 0;
        }

        &__image {
            display: block;
            width: 100%;

        }

    }

    &__form {


        .fancyForm {
            transition: transform .5s ease-out;
        }

        @include breakpoint(1024px) {
            margin-top: -8rem;
            width: 40%;
        }

    }

    &__related {
        padding: 4rem 0;

        h2 {
            font-size: rem(24);

            @include breakpoint($mobile-up) {
                font-size: rem(32);
                padding-left: rem(8);
                padding-right: rem(8);
            }
        }
    }

}


.resource__form {

    .fancyForm {

    }
}


@keyframes slideIn {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}
