.list {
	&--reset {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

.bg {
	&--grey {

		background-color: color(concrete);

        .inner {
            margin: 0 auto;
        }
	}
}

.clearfix {
    @extend %clearfix;
}
