.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.list--reset, .servicesSearch__companies .brandList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg--grey {
  background-color: #f3f3f3;
}

.bg--grey .inner {
  margin: 0 auto;
}

.servicesSearch__application {
  background-color: #50769d;
  box-shadow: inset 0px rem(14) rem(20) rem(-10) rgba(0, 0, 0, 0.2);
}

.servicesSearch__application .inner {
  max-width: 860px;
}

@media (min-width: 767px) {
  .servicesSearch__application .inner {
    padding-top: rem(56);
    padding-bottom: rem(56);
  }
}

.servicesSearch__application h2 {
  color: #ffffff;
}

.servicesSearch__application form {
  margin-top: rem(20);
}

.servicesSearch__application textarea {
  height: rem(246);
}

.servicesSearch__companies {
  text-align: center;
}

.servicesSearch__companies .brandList {
  list-style: none;
}

.servicesSearch__content {
  max-width: 860px;
  margin: 0 auto;
}
